export default class MService {
    mdata = [
        {
            id:4,
            avatar: 'S',
            title: 'СНТ Венеция',
            subheader: 'Январь 2020',
            opis: 'Сайт садоводческого товарищества.',
            img: '/img/4.png',
            tech: ['php', 'wordpress', 'mysql'],
            site: 'http://sntvenice.ru/'
        },
        {
            id:5,
            avatar: 'M',
            title: 'МУП ЦППН',
            subheader: 'Ноябрь 2019',
            opis: 'Сайт МУП ЦППН г.Находка.',
            img: '/img/5.png',
            tech: ['php', 'wordpress', 'mysql'],
            site: 'http://mupcppn.ru/'
        },
        {
            id:2,
            avatar: 'Б',
            title: 'Бинесс партнер',
            subheader: 'Июнь 2019',
            opis: 'Сайт компании по прокату автомобилей.',
            img: '/img/2.png',
            tech: ['php', 'laravel', 'javascript', 'mysql'],
            site: 'http://business-partner-prim.ru'
        },
        {
            id:1,
            avatar: 'C',
            title: 'АН Собственник',
            subheader: 'Март 2019',
            opis: 'Сайт агенства недвижимости.',
            img: '/img/1.png',
            tech: ['python', 'javascript', 'mysql', 'bitrix 24'],
            site: 'http://sobstvennik.designedbylab.ru'
        },
        {
            id:3,
            avatar: 'D',
            title: 'СПК DenizCilck',
            subheader: 'Январь 2018',
            opis: 'Сайт производственной компании.',
            img: '/img/3.png',
            tech: ['php', 'angular.js', 'mysql'],
            site: 'http://amtsltd.net/'
        }


    ];


    getWorks = () =>{
        return new Promise((resolve) => {
            setTimeout(()=>{
               resolve(this.mdata);
            }, 700);
        });
    }

    sendEmail = ({name, email, tema, message}) => {
        let dt = new FormData();
        dt.append('name', name);
        dt.append('email', email);
        dt.append('tema', tema);
        dt.append('message', message);
        return fetch('/m.php', {
            method: 'POST',
            body:dt
        });
    }


}